import React, { useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { BsXLg } from "react-icons/bs";
import Spinner from "../../components/spinner";
import './fileupload.css';
import { error } from 'console';

const acceptedFileType = ["CSV"];

const FileUpload = ({ clientId, handleOnClick, setDataList }) => {

  console.log(clientId);
  const [file, setFile] = useState('')
  //const [clientId, setClientId] = useState('')
  const [loading, setLoading] = useState(false);
  const [fetchPass, setFetchPass] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const backupErrorText = "Something went wrong. Please try again, or contact support if issue persists."

  const handleFileUpload = (uploadedFile) => {
    setFile(uploadedFile);
  }

  const handleSubmittion = async (e) => {
    e.preventDefault();
    setLoading(true)

    // add a conditional to let user know that if
    // there is no file, to please upload one
    if (file.length === 0) {
      setLoading(false)
      setUploadError(true);
      return;
    }

    const messageArea = document.querySelector('#message');
    if (file != undefined) {
      console.log(file);
      var formdata = new FormData();
      formdata.append("emailsFile", file);
      formdata.append("listName", `client${clientId.clientId}suppression`)

      var requestOptions: RequestInit = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch(`https://blacklists.smartrecognition.com/uploadEmails`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log('result', result.success);
          setFetchPass(true)})
        .catch(error => {console.log('error', error); setFetchError(true)});
      /*
            .then(res => res.json())
            .then(jso => {
              console.log(jso);
              if (jso.status == 200) {
                //setClientId('')
                messageArea!.classList.add('success')
                messageArea!.innerHTML = jso.statusText;
                handleOnClick(false)
              } else {
                messageArea!.classList.add('error')
                messageArea!.innerHTML = jso.statusText;
                if (messageArea!.innerHTML.length < 1) {
                  messageArea!.innerHTML = backupErrorText
                }
              }
              setLoading(false)
            })
            .catch(error => {
              messageArea!.classList.add('error')
              messageArea!.innerHTML = error.statusText;
              if (messageArea!.innerHTML.length < 1) {
                messageArea!.innerHTML = backupErrorText
              }
              setLoading(false)
            });
    */

    }
  }
  //<div className='form-input first'>
  //<label>Client ID </label>
  //<input type='text' placeholder='Client ID' value={clientId} onChange={e => { console.log(e.target.value); setClientId(e.target.value) }} />
  //</div>
  /*
  {fetchPass ? <button onClick={() => history.push('/accounts')} id="close-icon"><BsXLg /></button> :
  <button onClick={() => handleOnClick(false)} id="close-icon"><BsXLg /></button>}
  */
  return (
    <article id="file-upload-popup">
  {fetchPass ? <button onClick={() => window.location.href = '/accounts'} id="close-icon"><BsXLg /></button> :
  <button onClick={() => handleOnClick(false)} id="close-icon"><BsXLg /></button>}
      {fetchPass ? <h2>File successfully uploaded</h2> : (
        <>
          <h2>Upload File</h2>
          <p id="message"></p>
          {loading ? <Spinner /> : (
            <form>
              {uploadError && <p style={{ color: 'red' }}>Please choose a valid file to upload</p>}
              <FileUploader handleChange={(f) => handleFileUpload(f)} name="file" types={acceptedFileType} />
              <button className='btn--submit' type='submit' onClick={(e) => handleSubmittion(e)}>Submit</button>
            </form>
          )}
        </>
      )}
    </article>
  );


}

export default FileUpload
