export const LOAD_SCRIPTS = "LOAD_SCRIPTS";
export const LOAD_SCRIPTS_SUCCESS = "LOAD_SCRIPTS_SUCCESS";
export const UPDATE_SCRIPT_FIELD = "UPDATE_SCRIPT_FIELD";
export const LOAD_SCRIPT_BY_ID = "LOAD_SCRIPT_BY_ID";
export const LOAD_SCRIPT_BY_ID_SUCCESS = "LOAD_SCRIPT_BY_ID_SUCCESS";
export const CREATE_SCRIPT_SUCCESS = "CREATE_SCRIPT_SUCCESS";
export const UPDATE_SCRIPT_SUCCESS = "UPDATE_SCRIPT_SUCCESS";
export const REMOVE_SCRIPT_BY_ID = "REMOVE_SCRIPT_BY_ID";
export const REMOVE_SCRIPT_BY_ID_SUCCESS = "REMOVE_SCRIPT_BY_ID_SUCCESS";
export const SORT_SCRIPTS = "SORT_SCRIPTS";

export interface ScriptsState {
    scripts: Array<Script>
    scriptsSortOptions: {
        field: string
        direction: boolean
    }
    editedScript: Script
    loading: boolean
    loadingScript: boolean
}

export interface Script {
    id?: number
    client_id: string
    code: string
    uniquePerDay: number
    extraCredsPerBill: number
    sources: string
}

interface LoadScripts {
    type: typeof LOAD_SCRIPTS_SUCCESS | typeof LOAD_SCRIPTS,
    payload: Array<Script>
}

interface LoadScriptById {
    type: typeof LOAD_SCRIPT_BY_ID_SUCCESS | typeof LOAD_SCRIPT_BY_ID,
    payload: Script
}

interface UpdateScriptField {
    type: typeof UPDATE_SCRIPT_FIELD,
    payload: {
        field: string
        value: string
    }
}

interface UpdateScript {
    type: typeof UPDATE_SCRIPT_SUCCESS
    payload: any
}

interface CreateScript {
    type: typeof CREATE_SCRIPT_SUCCESS
    payload: any
}

interface RemoveScript {
    type: typeof REMOVE_SCRIPT_BY_ID_SUCCESS
    payload: any
}

interface SortScripts {
    type: typeof SORT_SCRIPTS,
    payload: any
}

interface DefaultAction {
    type: any
    payload?: any
}

export type ScriptActionTypes = LoadScripts | UpdateScriptField | LoadScriptById | LoadScripts | CreateScript | UpdateScript | RemoveScript | DefaultAction | SortScripts;
